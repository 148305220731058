"use client";

import { ReactNode, useEffect, useState } from "react";
import LoadingPage from "@/components/shared/LoadingPage";
import {
  LoginState,
  useAuthentication,
} from "@/providers/app/AuthenticationProvider";
import { useRouter } from "next/navigation";
import { AppRoutes } from "@/config/app/routes";
import InactiveAccountScreen from "@/providers/app/Authentication/InactiveAccountScreen";

interface AuthenticationGuardedProps {
  children: ReactNode;
  redirect?: boolean;
}

const AuthenticationGuarded = ({
  children,
  redirect = true,
}: AuthenticationGuardedProps) => {
  const [initialized, setInitialized] = useState(false);
  const { loginState } = useAuthentication();
  const router = useRouter();

  useEffect(() => {
    if (!redirect) return;
    if (loginState === LoginState.checking) {
      return;
    }
    if (!initialized) return;
    if (
      loginState === LoginState.not_logged_in ||
      loginState === LoginState.expired ||
      loginState === LoginState.cannot_login
    ) {
      setTimeout(() => {
        const url = new URL(
          window.location.origin +
            AppRoutes.login +
            "?redirect=" +
            window.location.pathname
        );
        router.replace(url.toString());
      }, 2000);
    }
  }, [initialized, loginState, redirect, router]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialized(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loginState === LoginState.checking)
    return <LoadingPage text="Đang đăng nhập..." />;

  if (loginState === LoginState.try_refresh_token)
    return <LoadingPage text="Đang lấy token mới" />;

  if (loginState === LoginState.expired)
    return <LoadingPage text="Phiên làm việc hết hạn. Đang đăng nhập lại..." />;

  if (loginState === LoginState.cannot_login)
    return <LoadingPage text="Không thể đăng nhập. Vui lòng thử lại sau" />;

  if (loginState === LoginState.not_logged_in) {
    return <LoadingPage text="Vui lòng đăng nhập" />;
  }
  if (loginState === LoginState.user_inactive) {
    return <InactiveAccountScreen />;
  }
  return children;
};

export function withAuthenticationGuarded(Component: () => ReactNode): any {
  return () => (
    <AuthenticationGuarded>
      <Component />
    </AuthenticationGuarded>
  );
}

export default AuthenticationGuarded;
